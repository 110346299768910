<template>
  <base-login-form @login="handleLogin" />
</template>

<script>
import BaseLoginForm from './BaseLoginForm'

/**
 * @module LoginForm
 */
export default {
  name: 'LoginForm',
  components: { BaseLoginForm },
  props: {
    homeRouteName: {
      type: String,
      required: true
    }
  },
  methods: {
    async handleLogin (data) {
      await this.$store.dispatch('syncUpGlobalAuth', {
        accessToken: data.access_token,
        refreshToken: '' // we dont have it at this endpoint
      })
      this.$notify.success(this.$t('success.logged_in_successfully'))

      const redirectTo = this.$route.query.redirect
      // do not redirect if the route contains login or logout. This will cause a loop.
      if (redirectTo && redirectTo.match(/logout|login/) === null) {
        if (redirectTo.includes('http')) {
          return this.$locationService.replace(redirectTo)
        } else {
          return this.$router.push({ path: redirectTo })
        }
      }

      this.$router.push({ name: this.homeRouteName })
    }
  }
}
</script>
