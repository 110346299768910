import { api } from './ApiInstanceInjector'

export const AuthApiService = {
  fetchUser () {
    return api.getData('user')
  },
  fetchUserBrands () {
    return api.getData('user/brands')
  },
  login (payload) {
    return api.postData('login', payload)
  },
  logout () {
    return api.postData('logout')
  }
}
