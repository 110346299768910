<template>
  <form class="BaseLoginForm" @submit.prevent="submit">
    <FormWrapper :validator="$v.form">
      <FormField #default="{ validator, classes }" name="email">
        <input
          v-model="validator.$model"
          :placeholder="$t('forms.your_email')"
          :class="classes"
          class="input"
          name="email"
          type="email"
        >
      </FormField>

      <FormField #default="{ validator, classes }" name="password">
        <input
          v-model="validator.$model"
          :placeholder="$t('forms.input_password')"
          :class="classes"
          class="input"
          type="password"
          name="password"
        >
      </FormField>
    </FormWrapper>

    <div class="links m-v-l">
      <VButton
        type="submit"
        :loading="form.isLoading || isLoading"
        class="is-black is-medium m-b-m"
      >
        {{ $t('pages.login.log_in') }}
      </VButton>

      <slot name="actions" />
    </div>
  </form>
</template>

<script>
import { required, email, minLength } from 'vuelidate/lib/validators'
import { templates } from 'vuelidate-error-extractor'

import Form from '@/services/forms/Form'
import FormField from '../../components/forms/FormField'
import VButton from '../../components/core/VButton'
import { AuthApiService } from '../../services/api/AuthApiService'

const { FormWrapper } = templates

/**
 * @module BaseLoginForm
 */
export default {
  name: 'BaseLoginForm',

  components: {
    FormField,
    FormWrapper,
    VButton
  },
  props: {
    isLoading: {
      type: Boolean,
      default: false
    }
  },

  data: () => ({
    form: new Form({
      email: { value: '', rules: { required, email } },
      password: { value: '', rules: { required, minLength: minLength(6) } }
    })
  }),

  validations () {
    return {
      form: this.form.rules()
    }
  },
  methods: {
    async submit () {
      this.$v.form.$touch()

      if (this.$v.form.$error) {
        return
      }

      try {
        const response = await this.form.submit(AuthApiService.login)
        this.$emit('login', response)
      } catch (error) {
        const status = this.$safeGet(error, 'response.status', 0)
        // we will skip 404 and 422 errors for now
        if (status === 404) {
          this.$notify.error({
            title: 'Oops...',
            message: this.$t('pages.login.incorrect_credentials')
          })
        } else if (status === 403) {
          this.$notify.error({
            title: 'Oops...',
            message: this.$t('pages.login.not_activated')
          })
        } else {
          this.$displayRequestError(error, this.$t('errors.error'))
        }

        this.form.password = ''
        this.$v.form.password.$reset()
      }
    }
  }
}
</script>
