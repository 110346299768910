<template>
  <div class="is-width-5/6 m-h-a">
    <div class="">
      <heading
        size="4"
        weight="bold"
        color="white"
        :serif="false"
      >
        {{ $t('pages.login.title') }}
      </heading>

      <login-form home-route-name="dashboard.dashboard" />
    </div>

    <div class="m-t-xxl has-text-centered">
      <router-link
        :to="{ name: 'password-recovery' }"
        class="has-text-white has-text-decoration-underline"
      >
        {{ $t('pages.login.forgotten_password') }}
      </router-link>
    </div>

    <div class="m-t-xl has-text-centered">
      <a
        :href="hfUrl"
        target="_blank"
        class="has-text-white has-text-decoration-underline"
      >
        {{ $t('pages.login.dont_have_acc_yet') }}
      </a>
    </div>
  </div>
</template>

<script>
import LoginForm from '@hypefactors/shared/js/components/auth/LoginForm'

export default {
  components: {
    LoginForm
  },
  data () {
    return {
      hfUrl: 'https://hypefactors.com/your-solution/'
    }
  }
}
</script>
